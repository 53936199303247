.mydiv {
    position: absolute;
    z-index: 1;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
}

#mydivheader {
    padding: 10px;
    cursor: move;
    z-index: 1;
    background-color: #2196F3;
    color: #fff;
}