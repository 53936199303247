.book-shelf-changer {
    
    right: 0;
    margin-top: 3px;
    width: 18px;
    height: 18px;
    background-image: url('./menu.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    justify-content: center;
  }
  .book-shelf-changer select {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }