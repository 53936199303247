.speed-holder {
    text-align: center;
    background-color: #6fb43d;
    border-radius: 50px;
    font-size: 100%;
    display: inline-block;
    line-height: normal !important;
    margin-right: 5px;
    padding-right: 2px;
    cursor: default;
    white-space: nowrap;
}
.speed {
    width: auto;
    color: #fff;
    margin: 0 .4em 0 0;
    font-size: 88%;
    cursor: default;
    vertical-align: top;
}