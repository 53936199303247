.label{
    font-size: 14px;
    margin-top: 10px;
    margin-right:3px;
    margin-left: 3px;
    background-color: white;
    border: 1px solid slategray;
}
.labelGeocerca{
    background-color: #1F69AD;
    z-index: 999;
    color: white;
}