
.main .container-fluid {
    padding: 0 0px;
}
.breadcrumb {
    margin-bottom: 0%;
}
.App{

}
.App-hidden{
visibility: hidden;
margin: 0 0 0 0;

}
.label{
    
  
}
.grillaVerOcultar{
    position: relative;
    top: 10vh; 
    left:8px; 
    width:30px;
    height:30px; 
    background-color:white;
    z-index:999;
    color:white;
    margin:0 0 0 0;
}
